<template>
  <div class="full-width full-height">
    <tab :tabs="tabs" :initTab="initTab" @select="selectHandle" />
    <div class="m-t flex">
      <div class="left">
        <sub-menu @toggleCurrent="selectCodeType"></sub-menu>
      </div>
      <div class="m-l-md f-r">
        <h4 class="text-lightblue f14 m-b-sm">字典大类：{{dictText}}</h4>
        <div class="flex justify-between items-center m-b-sm text-lightblue">
          <div class="flex items-center">
            <span class="flex-s m-r-xs">字典编码：</span>
            <a-input
              v-model="searchParams.dictCode"
              placeholder="请输入字典编码"
              class="m-r-sm  search-mx-wd-1"
            ></a-input>
            <span class="flex-s m-r-xs">字典值：</span>
            <a-input
              v-model="searchParams.dictValue"
              placeholder="请输入字典值"
              class="m-r-sm search-mx-wd-1"
            ></a-input>
            <a-button
              type="primary"
              class="m-r-sm btn-search"
              @click="searchHandle"
            >
              搜索
            </a-button>
            <a-button class="btn-reset m-r-sm" @click="resetHandle">
              重置
            </a-button>
          </div>
          <div class="flex">
            <span class="span-line m-l-md m-r-md"></span>
            <a-button class="btn-export m-r-sm" @click="addMulHandle">
              批量添加
            </a-button>
            <a-button class="btn-export" @click="addHandle">
              添加
            </a-button>
          </div>
        </div>

        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :components="resibleTableHeader"
          :rowKey="(row) => row.codeValue"
          :columns="columns"
          :data-source="data"
          :pagination="paginationOpt"
          :scroll="scroll"
          :loading="loading"
          bordered
        >
          <template slot="customOpe" slot-scope="text, record, index">
            <a-tooltip placement="top" v-show="record.sort != 1">
              <template slot="title">
                <span>向上移动</span>
              </template>
              <a-icon
                type="sort-descending"
                class="m-r-sm pointer"
                @click="handleTop(record, index)"
              />
            </a-tooltip>
            <a-tooltip placement="top" v-show="record.sort != 1">
              <template slot="title">
                <span>一键置顶</span>
              </template>
              <a-icon
                type="to-top"
                class="m-r-sm pointer"
                @click="stickHandle(record)"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>编辑</span>
              </template>
              <a-icon
                type="edit"
                class="m-r-sm pointer"
                @click="editHandle(record)"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>删除</span>
              </template>
              <a-icon type="rest" class="pointer" @click="delHandle(record)" />
            </a-tooltip>
          </template>
        </a-table>

        <div class="flex items-center p-w-md b20 relative">
          <a-checkbox v-model="checkAll"></a-checkbox>
          <a-button
            class="m-l-sm"
            type="primary"
            :disabled="getDisabled"
            @click="detachDel"
            >批量删除</a-button
          >
        </div>
      </div>
    </div>
    <single-dialog
      :visible="visibleSingle"
      :is-edit="isEdit"
      :record="currentRecord"
      :code-type="codeType"
      @close="closeSingle"
      @refreshData="refreshDataHandle"
    />
    <multiple-dialog
      :code-type="codeType"
      :visible="visibleMultiple"
      @close="closeMultiple"
      @refreshDataMul="refreshDataMulHandle"
    />
  </div>
</template>

<script>
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import SubMenu from './SubMenu';
import SingleDialog from './SingleDialog';
import MultipleDialog from './MultipleDialog';
import ResizableTable from '@/components/mixins/resizeableTable';
const CODE_TYPE_ARR = ['CLASSIFY', 'DERECTION', 'CAMERA_TYPE'];
const CODE_TEXT_ARR = ['摄像机位置类型', '摄像机方向', '摄像机类型'];
const CODE_OK = 200;
export default {
  name: 'CameraDict',
  mixins: [tabMixin,ResizableTable],
  data() {
    return {
      dictText:CODE_TEXT_ARR[0],
      scroll: { y: window.innerHeight - 400,x:'100%' },
      searchParams: {
        dictCode: '',
        dictValue: '',
      },
      columns: [
        {
          title: '字典编码',
          dataIndex: 'codeValue',
          width:100,
        },
        {
          title: '字典值',
          dataIndex: 'codeName',
          width:140
        },
        {
          title: '排序',
          dataIndex: 'sort',
          width:100
        },
        {
          title: '字典备注',
          dataIndex: 'remark',
          width:140,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '操作',
          dataIndex: 'ope',
          className:'column-center',
          width:100,
          scopedSlots: {
            customRender: 'customOpe',
          },
        },
      ],
      data: [],
      selectedRowKeys: [],
      visibleSingle: false,
      isEdit: false,
      visibleMultiple: false,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 4, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getCodeMaster();
          this.resetSelectRow();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getCodeMaster();
          this.resetSelectRow();
        },
      },
      codeType: CODE_TYPE_ARR[0],
      currentRecord: {},
      loading: false,
    };
  },
  computed: {
    checkAll: {
      get() {
        return (
          this.selectedRowKeys.length === this.data.length &&
          this.data.length !== 0
        );
      },
      set(val) {
        this.selectedRowKeys = val
          ? this.data.map((item) => item.codeValue)
          : [];
      },
    },
    getDisabled() {
      return this.selectedRowKeys.length <= 0;
    },
  },
  watch: {},
  created() {
    this._getCodeMaster();
    this.$nextTick(() => {
      this.getHeight();
    });
  },
  methods: {
    selectHandle(index) {
      this.selectCurrent(index);
    },
    getHeight() {
      let winHeight = window.innerHeight;
      return { height: `${winHeight - 300}px` };
    },
    _getCodeMaster() {
      let params = {
        codeName: this.searchParams.dictValue, //字典值
        codeType: this.codeType,
        codeValue: this.searchParams.dictCode, //字典编码
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
      };
      this.loading = true;
      this.$api.dict.getCodeMaster(params).then((res) => {
        if (res.code === CODE_OK) {
          // console.log(res);
          this.loading = false;
          this.data = res.data;
          this.paginationOpt.total = res.total;
        }
      });
    },
    selectCodeType(index) {
      this.codeType = CODE_TYPE_ARR[index];
      this.dictText = CODE_TEXT_ARR[index];
      this.resetHandle();
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getCodeMaster();
    },
    resetHandle() {
      this.searchParams = {
        dictCode: '',
        dictValue: '',
      };
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getCodeMaster();
    },
    refreshDataHandle() {
      this.visibleSingle = false;
      this.currentRecord = {};
      this.isEdit = false;
      this._getCodeMaster();
    },
    refreshDataMulHandle() {
      this.visibleMultiple = false;
      this._getCodeMaster();
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleTop(record, index) {
      let params = {
        codeType: this.codeType,
        codeValue: record.codeValue,
        sort: record.sort,
      };
      let copyData = this.data.slice();
      //把前一条数据，添加到后面那条
      copyData.splice(index, 1, this.data[index - 1]);
      //把当前数据，添加到上一个位置
      copyData.splice(index - 1, 1, record);
      // this.data = copyData.map((item, index) => {
      //   item.index = index;
      //   return item;
      // });

      this.$api.dict.updateByCode(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('上移成功！');
          this.data = copyData;
        }
      });
    },
    closeSingle() {
      this.visibleSingle = false;
      this.currentRecord = {};
      this.isEdit = false;
    },
    addHandle() {
      this.visibleSingle = true;
      this.isEdit = false;
    },
    editHandle(record) {
      this.visibleSingle = true;
      this.isEdit = true;
      this.currentRecord = record;
    },
    closeMultiple() {
      this.visibleMultiple = false;
    },
    addMulHandle() {
      this.visibleMultiple = true;
    },
    stickHandle(record) {
      let data = {
        codeType: this.codeType,
        codeValue: record.codeValue,
      };
      this.$api.dict.updateSort(data).then((res) => {
        if (res.code == CODE_OK) {
          this.$message.success('一键置顶成功');
          this._getCodeMaster();
        }
      });
    },
    delHandle(record) {
      this.$confirm({
        title: '提示',
        content: '你确定删除该字典数据吗?',
        onOk: () => {
          let params = {
            codeType: this.codeType,
            codeValue: record.codeValue,
          };
          this.$api.dict.deleteDict(params).then((res) => {
            if (res.code === CODE_OK) {
              this.$message.success('删除该字典数据成功！');
              this._getCodeMaster();
            }
          });
        },
        onCancel: () => {},
      });
    },
    /*  onCheckAll(val) {
      console.log(val);
    }, */
    detachDel() {
      this.$confirm({
        title: '提示',
        content: '你确定批量删除字典数据吗?',
        onOk: () => {
          let paramObj = {
            codeType: this.codeType,
            params: this.selectedRowKeys,
          };
          this.$api.dict.batchDeleteDict(paramObj).then((res) => {
            if (res.code === CODE_OK) {
              this.$message.success('批量删除成功！');
              this.searchHandle();
              this.resetSelectRow();
            }
          });
        },
        onCancel: () => {},
      });
    },
    resetSelectRow() {
      this.selectedRowKeys = [];
    },
  },
  components: {
    Tab,
    SubMenu,
    SingleDialog,
    MultipleDialog,
  },
};
</script>

<style scoped>
.left {
  flex: 0 0 200px;
  width: 200px;
}
.f-r {
  width: calc(100% - 210px);
}
.w20 {
  width: 60%;
}
.b20 {
  bottom: 20px;
}
</style>
