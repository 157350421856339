<template>
  <div>
    <a-modal
      title="批量添加"
      :visible="visible"
      width="40%"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="flex items-center m-b-sm">
          <span class="title">字典大类</span>
          <span>{{ getCodeType }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="title">字典编码</span>
          <a-input-number :min="0" v-model="params.code" />
          <span class="tip text-lightgray m-l-sm"
            >(数值开始编码值，步进1增量)</span
          >
        </div>
        <div class="m-b-sm">
          <div class="flex items-center m-b-xs">
            <span class="title">字典数据</span>
            <a-textarea
              placeholder="请输入数据内容"
              v-model="params.codeValue"
            />
          </div>
          <span class="text-lightgray f12 m-l-70"
            >数据分开方式：多个数据使用英文逗号(,)分开</span
          >
        </div>
        <div class="flex">
          <span class="title">备注信息</span>
          <a-textarea
            placeholder="备注信息"
            v-model="params.remark"
            :maxLength="200"
          />
        </div>
        <div class="flex m-t-md items-center justify-center">
          <a-button
            type="primary"
            class="btn-primary m-r-sm"
            @click="sureHandle"
            >确定</a-button
          >
          <a-button class="btn-reset" @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const CODE_OK = 200;
export default {
  name: 'SingleDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    codeType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      params: {
        code: 0,
        codeValue: '',
        remark: '项目备注',
      },
    };
  },
  computed: {
    getCodeType() {
      return this.codeType === 'DERECTION'
        ? '摄像机方向'
        : this.codeType === 'CAMERA_TYPE'
        ? '摄像机类型'
        : '摄像机位置类型';
    },
  },
  watch: {},
  methods: {
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    },
    sureHandle() {
      let params = {
        codeName: this.params.codeValue.trim(),
        codeType: this.codeType,
        codeValue: this.params.code,
        remark: this.params.remark.trim(),
      };
      if (!params.codeName) {
        this.$message.error('字典值不能为空！');
        return;
      }
      let reg = /^(\w|[\u4e00-\u9fa5])+(,(\w|[\u4e00-\u9fa5])+)*$/;
      if (!reg.test(params.codeName)) {
        this.$message.warning('多个字典数据请使用英文逗号(,)分开');
        return;
      }
      this.$api.dict.batchInsertDict(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('批量添加成功');
          this.resetHandle();
          this.$emit('refreshDataMul');
        } else {
          this.$message.error(res.message);
        }
      });
    },
    resetHandle() {
      this.params = {
        code: 0,
        codeValue: '',
        remark: '项目备注',
      };
    },
  },
};
</script>

<style scoped>
.title {
  flex: 0 0 70px;
}
.tip {
  flex: 0 0 240px;
}
.m-l-70 {
  margin-left: 70px;
}
</style>
