<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      width="40%"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="flex items-center m-b-sm">
          <span class="title">字典大类</span>
          <span>{{ getCodeType }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="title">字典编码</span>
          <a-input v-model="params.code" placeholder="请输入字典编码" />
          <span class="equal m-l-sm m-r-sm text-center">=</span>
          <a-input
            v-model="params.codeValue"
            placeholder="请输入字典值"
            :maxLength="50"
          />
        </div>
        <div class="flex">
          <span class="title">备注信息</span>
          <a-textarea
            placeholder="备注信息"
            v-model="params.remark"
            :maxLength="200"
          />
        </div>
        <div class="flex m-t-md items-center justify-center">
          <a-button
            type="primary"
            class="btn-primary m-r-sm"
            @click="sureHandle"
            >确定</a-button
          >
          <a-button class="btn-reset" @click="handleCancel">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const CODE_OK = 200;
export default {
  name: 'SingleDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    codeType: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      params: {
        code: 0,
        codeValue: '默认',
        remark: '项目备注',
      },
    };
  },
  computed: {
    title() {
      return `${this.isEdit ? '修改' : '新增'}字典项目`;
    },
    getCodeType() {
      return this.codeType === 'DERECTION'
        ? '摄像机方向'
        : this.codeType === 'CAMERA_TYPE'
        ? '摄像机类型'
        : '摄像机位置类型';
    },
  },
  watch: {
    isEdit(nv) {
      if (nv) {
        this.initData();
      }
    },
  },
  methods: {
    initData() {
      this.params = {
        code: this.record.codeValue,
        codeValue: this.record.codeName,
        remark: this.record.remark,
      };
    },
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    },
    sureHandle() {
      let params = {
        codeName: this.params.codeValue.trim(),
        codeType: this.codeType,
        codeValue: this.params.code,
        remark: this.params.remark.trim(),
      };
      if (!params.codeName) {
        this.$message.error('字典值不能为空！');
        return;
      }
      if (!String(params.codeValue)) {
        this.$message.error('字典编码不能为空！');
        return;
      }
      if (this.isEdit) {
        // console.log(1);
        let paramObj = {
          codeValue: this.record.codeValue,
          params,
        };
        this.$api.dict.updateDict(paramObj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('修改成功');
            this.resetHandle();
            this.$emit('refreshData');
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$api.dict.insertDict(params).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('新增成功');
            this.resetHandle();
            this.$emit('refreshData');
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    resetHandle() {
      this.params = {
        code: 0,
        codeValue: '默认',
        remark: '项目备注',
      };
    },
  },
};
</script>

<style scoped>
.title {
  flex: 0 0 70px;
}
.equal {
  text-align: center;
  border: 1px solid #4c658a;
  line-height: 32px;
  flex: 0 0 40px;
  border-radius: 3px;
}
</style>
