<template>
  <div>
    <h2 class="title p-w-sm f16">摄像机信息管理</h2>
    <ul class="list">
      <li
        v-for="(item, index) in list"
        :key="index"
        class="text-lightblue pointer"
        :class="{ current: index === curIndex }"
        @click="selectItem(index)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SubMenu',
  data() {
    return {
      list: ['摄像机位置类型', '摄像机方向', '摄像机类型'],
      curIndex: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    selectItem(index) {
      this.curIndex = index;
      this.$emit('toggleCurrent', index);
    },
  },
};
</script>

<style scoped>
.title {
  color: rgb(193, 218, 249);
  line-height: 34px;
}
.list {
  padding-left: 20px;
  line-height: 28px;
  width: 60%;
}
.list li {
  border-bottom: 1px solid transparent;
}
.list li.current {
  border-bottom-color: rgb(193, 218, 249);
}
</style>
